function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/* Growity */
function getGrowityParams() {
    var cd1 = '', cd2 = '';
    if (window.growity && window.growity.config) {
        cd1 = window.growity.getCompiledDimensions && window.growity.getCompiledDimensions(window.growity.config.expsVariants);
        cd2 = window.growity.config.growityId;
    }

    return { cd1: cd1, cd2: cd2 };
}

jQuery(function($) {

    /* Growity */
    var growityParams = getGrowityParams();
    if (growityParams.cd1.length) {
        document.cookie = "growityCd1=" + growityParams.cd1 + ";path=/";
    }

    /*
     * Offset content by the current header height
     */

    $('.content').css('margin-top', $('.header').height());

    $(window).resize(function() {
        $('.content').css('margin-top', $('.header').height());
    });

    /*
     * General scrolling to an anchor
     */

    var jump = function(e) {
        if (e) {
            e.preventDefault();
            var target = $(this).attr('href');
        } else {
            var target = location.hash;
        }

        $('.header-mobile-menu').hide();

        $('html,body').animate({
            scrollTop: $(target).offset().top - $('.header').height()
        }, 500, function() {
            if (history.pushState) {
                history.pushState(null, null, target);
            }
            else {
                location.hash = target;
            }
        });
    }

    $('html, body').hide();

    $('a[href^="#"]').click(jump);

    if (location.hash) {
        setTimeout(function() {
            $('html, body').scrollTop(0).show();
            jump();
        }, 0);
    } else {
        $('html, body').show();
    }

    /*
     * Mobile menu
     */

    /* Toggle mobile menu */
    $('.mobile-menu-icon').click(function() {
        $('.header-mobile-menu').toggle();
    });

    /*
     * Background video
     */

    /* Load background video */
    function loadVideo(videoSrc) {
        var breakpoint = 768;

        var video = $('.bg-video video')[0];

        /* Add source tags if not already present */
        if ($(document).width() >= breakpoint) {
            if (document.querySelectorAll(".bg-video video > source").length < 1) {
                var source1 = document.createElement('source');

                source1.setAttribute('src', videoSrc);
                source1.setAttribute('type', 'video/mp4');

                video.appendChild(source1);
            }

            /* Play the video */
            $('.bg-video video')[0].play();
        }
    }
    //loadVideo('/media/pages/home/bg-video.mp4'); TODO: use this instead of hardcoded html source

    /*
     * Scroll down button
     */

    /* Hide scroll button when inappropriate */
    if(
        $('#hero')[0] &&
        (
            $('#hero').outerHeight(true) > $(window).height() ||
            $('.scroll-down-btn').offset().top < ($('.hero-content').offset().top + $('.hero-content').outerHeight(false))
        )
    ) {
        $('.scroll-down-btn').hide();
        $('#hero').css('padding-bottom', $('#hero').css('padding-top'));
    }

    /* Scroll function of the button */
    $('.scroll-down-btn').click(function() {
        $(window).scrollTop(
            $('#hero + div').offset().top
            - Math.max($('.header').outerHeight(true), $('.header-mobile').outerHeight(true))
            + 1
        );
    });

    /*
     * Toggle popups
     */
    
    /* Open the appropriate popup */
    $('[data-popup]').click(function() {
        $('.popup-container[data-popup="' + $(this).data('popup') + '"]').show();
    });

    /* Close popup */
    $('.popup-container').click(function() {
        $('.popup-container').hide();
    });

    /* Do not close popup if clicked inside the content area */
    $('.popup-content').click(function() {
        event.stopPropagation();
    });

    /*
     * Slider
     */

    var enabledSlides = [1, 2, 4, 5, 6];
    var enabledSlidesIndex = 0;
    var visibleSlide = enabledSlides[enabledSlidesIndex];
    var slidesCount = enabledSlides.length;

    if ($('#slide-' + visibleSlide)) {
        setInterval(function() {
            $('#slide-' + visibleSlide)
                .animate(
                {
                    width: 'toggle'
                },
                300,
                function() {
                    enabledSlidesIndex = (enabledSlidesIndex + 1) % slidesCount;
                    visibleSlide = enabledSlides[enabledSlidesIndex];

                    $('#slide-' + visibleSlide).fadeIn(300);

                    $('[id^=slider-dot-]').css('background-color', 'rgba(255, 255, 255, 0.5)');

                    $('#slider-dot-' + visibleSlide)
                        .css('background-color', 'rgba(255, 255, 255, 1)');
                }
            );
        }, 6000);
    }

    /* Normalize slide heights */
    var slideHeightMax = 0;

    $('.slide').each(function(index) {
        if (index > 0) {
            $(this).show();
        }

        slideHeightMax = Math.max($(this).outerHeight(), slideHeightMax);

        if (index != 0) {
            $(this).hide();
        }
    });

    var slideMinHeight = Math.max(slideHeightMax, Math.floor(window.innerHeight * 0.8));

    $('.slide').each(function() {
        $(this).css('min-height', '' + slideMinHeight + 'px');
    });

    /*
     * Step reviews
     */

    var currentReviewIndex = 0;

    if (typeof reviews === 'object' && Array.isArray(reviews) && reviews.length > 1) {
        setInterval(function() {
            $('#reviews p').fadeOut(300, function() {
                currentReviewIndex = (currentReviewIndex + 1) % reviews.length;
                $('#reviews p').html(reviews[currentReviewIndex]);
                $('#reviews p').fadeIn(300);
            });
        }, 4000);
    }

    /*
     * Show safety text
     */

    $('#safety button').click(function() {
        $('#safety button').hide();
        $('#safety p').show();
    });

    /*
     * Show more FAQs
     */

    $('#faq button').click(function() {
        $('#faq button').hide();
        $('#faq .accordion-item').show();
    });

    /*
     * Accordion
     */

    $('.accordion .question').click(function() {
        $(this).next().toggle();
    });

    /*
     * Magnify images
     */

    $('.gallery-navigation').click(function(event) {
        event.stopPropagation();
    });

    $('.gallery-navigation-left').click(function(event) {
        if (magnifiedImageOrdinal > 0) {
            $('#magnified-image').attr('src', $('[data-ordinal=' + (magnifiedImageOrdinal - 1) + ']').attr('src'));
            magnifiedImageOrdinal--;
        }

        if (magnifiedImageOrdinal == 0) {
            $('.gallery-navigation-left').hide();
        }

        if (parseInt($('.magnifiable').last().data('ordinal')) > 0) {
            $('.gallery-navigation-right').show();
        }
    });

    $('.gallery-navigation-right').click(function(event) {
        if (magnifiedImageOrdinal < parseInt($('.magnifiable').last().data('ordinal'))) {
            $('#magnified-image').attr('src', $('[data-ordinal=' + (magnifiedImageOrdinal + 1) + ']').attr('src'));
            magnifiedImageOrdinal++;
        }

        if (magnifiedImageOrdinal == parseInt($('.magnifiable').last().data('ordinal'))) {
            $('.gallery-navigation-right').hide();
        }

        if (parseInt($('.magnifiable').last().data('ordinal')) > 0) {
            $('.gallery-navigation-left').show();
        }
    });

    $('.magnifiable').click(function() {
        magnifiedImageOrdinal = parseInt($(this).data('ordinal'));
        $('#magnified-image').attr('src', $(this).attr('src'));
        $('#magnified').show();

        if (
            parseInt($('.magnifiable').last().data('ordinal')) > $(this).data('ordinal') &&
            $( window ).width() >= 768
        ) {
            $('.gallery-navigation-right').show();
        } else {
            $('.gallery-navigation-right').hide();
        }

        if (
            0 < $(this).data('ordinal') &&
            $( window ).width() >= 768
        ) {
            $('.gallery-navigation-left').show();
        } else {
            $('.gallery-navigation-left').hide();
        }
    });

    $('#magnified').click(function() {
        $(this).hide();
    });

    $('#accept-cookies').click(function() {
        document.cookie = "cookies_accepted=1;path=/";
        $('#cookie-bar').hide();
    });

    if (!getCookie("cookies_accepted")) {
        $('#cookie-bar').show();
    }

});

/* Contact form action */

if (document.getElementById('contact-form')) {
    new Vue({
        el: '#contact-form',

        data: {
            state: 'initial',
            url: document.getElementById('contact-form').action,
            name: '',
            email: '',
            message: ''
        },

        methods: {
            submitForm() {
                var vm = this;

                vm.state = 'processing';

                axios.post(
                    vm.url,
                    {
                        name: vm.name,
                        email: vm.email,
                        message: vm.message
                    }
                )
                .then(function (response) {
                    if (response.data == 'success') {
                        vm.state = 'success';
                        vm.name = '';
                        vm.email = '';
                        vm.message = '';

                        setTimeout(
                            function () {
                                vm.state = 'initial';
                            },
                            4000
                        );
                    } else {
                        vm.state = 'failure';

                        setTimeout(
                            function () {
                                vm.state = 'initial';
                            },
                            4000
                        );
                    }
                })
                .catch(function (error) {
                    vm.state = 'failure';

                    setTimeout(
                        function () {
                            vm.state = 'initial';
                        },
                        4000
                    );
                });
            }
        }
    });
}
